import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { postMembershipOrder, postMembershipOrderSummary } from '../../actions/membership';
import { navigate } from 'gatsby';
import { displayCurrency } from '../../helpers/currency';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import Layout from '../../components/v2/Layout';
import Button from '../../components/v2/Button';
import Payment from '../../components/Payment';
import Promotion from '../../components/Promotion';
import CheckoutSummary from '../../components/CheckoutSummary';
import { checkConsent, postAcceptConsent } from '../../apis';
import { Dialog, DialogActions, DialogContent, DialogContentText, IconButton, TableBody, TableRow } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Table from '../../components/Table';
import Cell from '../../components/Table/cell';

const useStyles = makeStyles(theme => ({
  dialogContent: {
    padding: 0
  },
  closeButton: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: 'black',
    padding: '5px'
  },
  dialogAction: {
    justifyContent: 'center',
  },
  buttonConsent: {
    color: 'black',
    textDecoration: 'underline',
    fontSize: '11px',
  },
  consentText: {
    color: 'blue',
  }
}));

export default function Checkout() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [check, setCheck] = React.useState(false);
  const [summary, setSummary] = React.useState();
  const [consents, setConsents] = React.useState();
  const [liveInThai, setLiveInThai] = React.useState(false);
  const { plan, payment, promotionId, buyFor } = useSelector(state => state.orderInfo);

  React.useEffect(() => {
    if (plan) {
      const { cardtype } = plan;
      window.logEvent('begin_checkout', { currency: 'THB', value: cardtype?.price });
    }
  }, [plan]);

  const onCheckChange = (e) => {
    setCheck(!check);
  }

  const consentCheck = async (consentName) => {
    try {
      const res = await checkConsent(consentName);
      setConsents(res.consent);
    } catch (e) {}
  }

  const showButton = payment && summary;

  React.useEffect(() => {
    fetchSummary();
  }, [promotionId]);

  const fetchSummary = async () => {
    try {
      const res = await dispatch(postMembershipOrderSummary());
      setSummary(res);
    } catch (error) {
      setSummary();
    }
  }

  const confirm = async () => {
    try {
      let payload = {live_in_thailand: liveInThai};
      payload.consent_name = process.env.GATSBY_CONSENT_TERM_OF_USE
      await postAcceptConsent(payload);
      payload.consent_name = process.env.GATSBY_CONSENT_MEMBERSHIP
      await postAcceptConsent(payload);
      const res = await dispatch(postMembershipOrder());
      const url = res.payment_redirect_uri;
      if (url) {
        if (url.includes('/scanPromptpay?')) {
          navigate(`/scanPromptpay?${url.split('/scanPromptpay?')[1]}`);
        } else if (url.includes('/mbanking?')) {
          navigate(`/mbanking?${url.split('/mbanking?')[1]}`);
        } else {
          navigate(`/result/cardpayment?uri=${url}`);
        }
      } else {
        throw 'error';
      }
    } catch (e) {
      alert('มีบางอย่างผิดพลาด กรุณาลองใหม่อีกครั้ง');
    }
  }

  return (
    <Layout bg='bg2' title='สรุปการสั่งซื้อ'>
      <Box>
        <Box mb={1}>
          <Typography variant='h6' component='h1' color='textPrimary'>
            ข้อมูลแพคเกจ
          </Typography>
          <Typography variant='subtitle1' component='p' color='textPrimary'>
            1. แพคเกจ: {plan?.cardtype?.name}
          </Typography>
          <Box display='flex' justifyContent='space-between' ml={2}>
            <Typography variant='subtitle1' component='span' color='primary'>
              {displayCurrency(plan?.cardtype?.price)}
            </Typography>
            <Typography variant='subtitle1' component='span' color='textPrimary'>
              x1
            </Typography>
          </Box>
        </Box>
        <Box mb={1}>
          <Typography variant='h6' component='h1' color='textPrimary'>
            ช่องทางชำระเงิน
          </Typography>
          <Payment title={false} />
        </Box>
        <Box mb={1}>
          <Typography variant='h6' component='h1' color='textPrimary'>
            โค้ดส่วนลด
          </Typography>
          <Promotion title={false} filter={'membership'} />
        </Box>
        <Box mb={1}>
          <Typography variant='h6' component='h1' color='textPrimary'>
            สรุป
          </Typography>
          <CheckoutSummary title={false} summary={summary} />
        </Box>
        {
          showButton && (
            <>
              <Table>
                <TableBody>
                  <TableRow>
                    <Cell>
                      <input onChange={() => setLiveInThai(!liveInThai)} type='checkbox' id='live' name='live' checked={liveInThai} />
                    </Cell>
                    <Cell>
                      <Typography variant='subtitle2' component='p' color='textPrimary'>อาศัยอยู่ในประเทศไทย</Typography>
                    </Cell>
                  </TableRow>
                  <TableRow>
                    <Cell style={{ display: 'flex', alignItems: 'flex-start'}}>
                      <input onChange={onCheckChange} type='checkbox' id='consent' name='consent' checked={check} />
                    </Cell>
                    <Cell>
                      <Typography variant='subtitle2' component='p' color='textPrimary'>
                        เมื่อท่านกดเครื่องหมายที่ช่องนี้ ถือว่าท่านยินยอมใน <a className={classes.consentText} onClick={() => consentCheck(process.env.GATSBY_CONSENT_TERM_OF_USE)}>
                        เงื่อนไขการบริการของเรา</a> และยืนยันว่าท่านได้อ่าน <a className={classes.consentText} onClick={() => consentCheck(process.env.GATSBY_CONSENT_PRIVACY_POLICY)}>
                        นโยบายความเป็นส่วนตัว</a> ของเราแล้ว รวมถึงท่านยอมรับใน <a className={classes.consentText} onClick={() => consentCheck(process.env.GATSBY_CONSENT_MEMBERSHIP)}>
                        เงื่อนไขการเป็นสมาชิกของเรา</a> ทั้งนี้ ท่านสามารถยกเลิกการสมัครสมาชิกได้ตลอดเวลา
                      </Typography>
                    </Cell>
                  </TableRow>
                </TableBody>
              </Table>
              <Dialog
                fullScreen
                open={!!consents}
                onClose={() => setConsents()}
                scroll='body'
              >
                <DialogContent>
                  <IconButton aria-label='close' className={classes.closeButton} onClick={() => setConsents()}>
                    <CloseIcon />
                  </IconButton>
                  <DialogContentText
                    tabIndex={-1}
                    style={{ fontSize: '0.9em', color: 'black' }}
                  >
                    <div dangerouslySetInnerHTML={{ __html: consents }} />
                  </DialogContentText>
                </DialogContent>
                <DialogActions className={classes.dialogAction}>
                  <Button className={classes.buttonConsent} onClick={() => setConsents()} variant='text'>
                    ปิดหน้านี้
                  </Button>
                </DialogActions>
              </Dialog>
              <Box mt={1}>
                <Divider />
              </Box>
              <Box mt={3}>
                <Button
                  fullWidth
                  size='large'
                  variant='contained'
                  color='primary'
                  disabled={!check}
                  onClick={confirm}
                >
                  ชำระเงิน {displayCurrency(summary?.grand_total_price)}
                </Button>
              </Box>
              {
                buyFor === 'self' && (
                  <span style={{ fontSize: 10, color: 'red' }}>* หากคุณเป็นสมาชิกอยู่แล้ว จะไม่สามารถซื้อเพิ่มให้ตัวเองได้จนกว่าจะหมดอายุ</span>
                )
              }
            </>
          )
        }
      </Box>
    </Layout>
  )
}

